import React from "react";
import Header from "components/Web/Headers/Header";
import Footer from "components/Web/Footers/Footer";
import {connect} from "react-redux";
import PersonIcon from '@material-ui/icons/Person';
import logo from "assets/img/logo/logo.png";
import android from "assets/img/icons/android.png";
import apple from "assets/img/icons/apple.png";
import bazaar from "assets/img/downloads/bazaar.png";
import myket from "assets/img/downloads/myket.png"
import {TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody, ModalHeader, Row, Col, Spinner} from 'reactstrap';
import * as Router from "react-router-dom";
import classnames from 'classnames';
import {customList} from "country-codes-list";
import ReactCountryFlag from "react-country-flag";
import {LoginManual, logout, VerifyManual, RegisterManual} from "redux/actions/auth/loginActions";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import Notify from "components/Baran/noty/notify";
import {AvForm, AvGroup, AvField} from "availity-reactstrap-validation";
import Select from "react-select";
import {Button} from "@material-ui/core";
import Radio from "components/Baran/radio/RadioBaran";
import {history} from "../history";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import {Roles} from "../components/roles/ProjectRole";
import {isBrowser, isMobile} from "react-device-detect";
//images
import play from "../assets/img/downloads/androidPlay.png";
import sibiranilogobanner from "../assets/img/downloads/sibiranilogobanner.png";
import anardoniBadgeDarkPersian from "../assets/img/downloads/anardoniBadgeDarkPersian.png";
import IappsBadgeGray from "../assets/img/downloads/IappsBadgeGray.png";
import {WhatsApp} from "@material-ui/icons";
import defaultBackground from "../assets/img/theme/primary.jpg";

const $ = window.$;
const ContextLayout = React.createContext()

class PageLayout extends React.Component {

    constructor(props) {
        super(props);
        this.anchorRef = React.createRef();
        //this.nameInput = React.createRef();
        this.state = {
            modal: false,
            support: false,
            modal_download: false,
            activeTab: '1',
            country: 'IR',
            login_step: 0,
            verify_token: '',
            register_token: '',
            time: 90,
            timer: 0,
            reSendVerifyEnabled: false,
            showTime: "00:00",
            interval: null,
            isLoading: false,
            selectedIndex: 1,
            open: false,
            login_values: {},
            radioSelect: 0,
            token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
    }

    componentDidMount() {

    }

    selectCountry(val) {
        this.setState({country: val});
    }

    toggleTab = (val) => {
        this.setState({
            login_step: 0,
            login_values: {},
            activeTab: val
        })
    }

    toggleModal = () => {
        //this.nameInput.current.focus();
        this.setState({
            modal: !this.state.modal
        })
    }

    toggleModalDownload = () => {
        this.setState({
            modal_download: !this.state.modal_download
        })
    }

    toggleSupport = () => {
        this.setState({
            support: !this.state.support
        })
    }

    startTime = () => {
        let parent = this;
        let interval = setInterval(() => {
            let time = parent.state.timer -= 1;
            if (time === 0) {
                clearInterval(interval);
                parent.setState({
                    reSendVerifyEnabled: true
                });
            }
            parent.setState({
                timer: time
            }, function () {
                parent.setState({
                    showTime: parent.secondsToHms(parent.state.timer)
                })
            })
        }, 1000);
        this.setState({
            interval: interval
        })
    }

    secondsToHms(d) {
        d = Number(d);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let mDisplay = m > 0 && m >= 10 ? m + ":" : "0" + m + ":";
        let sDisplay = s > 0 && s >= 10 ? s : "0" + s;
        return mDisplay + sDisplay;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user) {
            if (prevProps.user !== this.props.user) {
                if (this.props.user.CheckPhoneToken) {
                    this.setState({
                        login_step: 1,
                        verify_token: this.props.user.CheckPhoneToken,
                        timer: this.state.time,
                    }, function () {
                        this.startTime();
                    })
                    this.phoneForm && this.phoneForm.reset();
                    this.emailForm && this.emailForm.reset();
                    this.phoneCodeForm && this.phoneCodeForm.reset();
                    this.emailCodeForm && this.emailCodeForm.reset();
                } else if (this.props.user.CheckPhoneError) {
                    let errors = this.props.user.CheckPhoneError;
                    let message = '';
                    Object.entries(errors).map(result => {
                        result[1].map(function (value) {
                            message += value + '\n';
                        });
                    });
                    Notify('info', 'top-right', message);
                } else if (this.props.user.Error) {
                    let errors = this.props.user.Error;
                    let message = '';
                    Object.entries(errors).map(result => {
                        result[1].map(function (value) {
                            message += value + '\n';
                        });
                    });
                    Notify('info', 'top-right', message);
                } else if (this.props.user.roles) {
                    //history.push('/')
                }
                this.setState({
                    isLoading: false,
                })
                if (typeof this.props.user.checkLoginStatus != "undefined") {
                    if (this.props.user.checkLoginStatus === 0) {
                        this.infoForm && this.infoForm.reset();
                        this.phoneCodeForm && this.phoneCodeForm.reset();
                        this.emailCodeForm && this.emailCodeForm.reset();
                        this.setState({
                            login_step: 2,
                            register_token: this.props.user.checkLoginToken
                        })
                    } else {
                        this.setState({
                            modal: false,
                        })
                    }
                }
                if (this.props.user.token) {
                    this.setState({
                        modal: false,
                    })
                }
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({
            country: selectedOption
        })
    }

    checkLogin = (event, errors, values) => {
        values.status = values.phone ? 0 : 1;
        values.country_code = values.phone && (this.state.country.label ? this.state.country.label.props.children[0].substring(0, 2) : this.state.country);
        if (errors.length === 0) {
            this.setState({
                login_values: values,
                isLoading: true,
                reSendVerifyEnabled: false
            })
            this.props.LoginManual(values)
        } else
            Notify('info', 'top-left', "لطفا اطلاعات فرم را به درستی تکمیل کنید");
    }

    resendCode = () => {
        this.setState({
            isLoading: true,
            reSendVerifyEnabled: false
        })
        this.props.LoginManual(this.state.login_values)
    }

    login = (event, errors, values) => {
        values.verify_token = this.state.verify_token;
        if (errors.length === 0) {
            this.setState({
                isLoading: true
            })
            this.props.VerifyManual(values)
        } else
            Notify('info', 'top-left', "لطفا اطلاعات فرم را به درستی تکمیل کنید");
    }

    register = (event, errors, values) => {

        values.register_token = this.state.register_token;
        values.gender = this.state.radioSelect;
        if (errors.length === 0) {
            this.setState({
                isLoading: true
            })
            this.props.RegisterManual(values)
        } else
            Notify('info', 'top-left', "لطفا اطلاعات فرم را به درستی تکمیل کنید");
    }

    stepBack = () => {
        this.setState({
            login_step: 0,
        })
    }

    exit = (user) => {
        if (user.token) {
            this.props.logout()
            this.setState({
                login_step: 0,
                login_values: {}
            })
        }
    }

    handleMenuItemClick = (event, index) => {
        this.setState({
            selectedIndex: index,
            open: false
        })
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        })
    };

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({
            open: false
        })
    };

    onClickRadio = (radio) => {
        this.setState({
            radioSelect: radio
        })
    }

    goToPanel = () => {
        if (this.props.user.roles.includes(Roles.Student))
            history.push('/panel/my-courses')
        else if (this.props.user.roles.includes(Roles.Teacher))
            history.push('/panel/teacher-courses')
        else
            history.push('/panel/dashboard')
    }

    download = (url) => {
        window.open(url, "_blank")
    }

    onKeyPressPhone = (e) => {
        let re = /\d|\w|[\.\$@\*\\\/\+\-\^\!\(\)\[\]\~\%\&\=\?\>\<\{\}\"\'\,\:\;\_]/g;
        let a = e.key.match(re);
        if (a == null)
            e.target.value = "";
    }

    render() {
        const customStyles = {
            menu: (provided, state) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                textAlign: 'left'
            }),
            input: (provided, state) => ({
                ...provided,
                width: '50px',
                border: 'none'
            }),
            singleValue: (provided, state) => {
                const transition = 'opacity 300ms';
                return {...provided, transition};
            },
            control: (provided, state) => ({
                ...provided,
                border: '1px solid #e0e0e0 !important',
                borderLeft: '0 !important',
                minHeight: '37.5px !important',
                borderTopLeftRadius: '2rem',
                borderBottomLeftRadius: '2rem',
                borderRight: '0 !important',
            }),
        }
        let listCountryCode = Object.entries(customList('countryCallingCode', '{countryCode}: +{countryCallingCode}')).map(function (value, index) {
            let label = value[1];
            if (value[0] === "1")
                return {
                    value: "1",
                    label: <>
                        US: +1
                        <ReactCountryFlag
                            className="country-flag"
                            countryCode={label.substring(0, 2)}
                            svg
                        />
                    </>
                }
            return {
                value: value[0],
                label: <>
                    {label}
                    <ReactCountryFlag
                        className="country-flag"
                        countryCode={label.substring(0, 2)}
                        svg
                    />
                </>
            }
        });
        const login_modal = <Modal size="md" className="login-modal" centered isOpen={this.state.modal}
                                   toggle={() => this.toggleModal()}>
            <ModalHeader>
                <div className="icon-wrapper"><PersonIcon/></div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '1'})}
                            onClick={() => {
                                this.toggleTab('1');
                            }}

                        >
                            شماره موبایل
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '2'})}
                            onClick={() => {
                                this.toggleTab('2');
                            }}
                        >
                            آدرس ایمیل
                        </NavLink>
                    </NavItem>
                </Nav>
            </ModalHeader>
            <ModalBody>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                {this.state.login_step === 0 ?
                                    <AvForm onSubmit={this.checkLogin} ref={c => (this.phoneForm = c)}>
                                        <label className="mb-1">
                                            شماره موبایل خود را بدون صفر وارد نمایید
                                        </label>
                                        <AvGroup className="input-wrapper">
                                            <AvField
                                                name="phone"
                                                type="text"
                                                dir="ltr"
                                                value={this.state.login_values.phone}
                                                className="form-control phone-input"
                                                placeholder="9**"
                                                onChange={this.editForm}
                                                onKeyUp={this.onKeyPressPhone}
                                                validate={{
                                                    required: {value: true, errorMessage: 'الزامی است'}
                                                }}
                                                //ref={this.nameInput}
                                                autoComplete="off"></AvField>
                                            {/*<Select
                                                name="country"
                                                id="country"
                                                placeholder={<span> IR:+98<ReactCountryFlag
                                                    className="country-flag text-left"
                                                    countryCode="IR"
                                                    svg
                                                /></span>}
                                                styles={customStyles}
                                                className="my-0 mb-1 input-country"
                                                isSearchable
                                                value={this.state.country}
                                                onChange={this.handleChange}
                                                options={listCountryCode}
                                                required
                                            />*/}
                                        </AvGroup>
                                        <Button className="submit-login" type="submit">
                                            {this.state.isLoading ?
                                                <>
                                                    <Spinner color="primary" className="reload-spinner"/>
                                                </>
                                                :
                                                <>
                                                    دریافت کد تایید
                                                </>
                                            }
                                        </Button>
                                    </AvForm> : this.state.login_step === 1 ?
                                        <AvForm onSubmit={this.login} className="position-relative"
                                                ref={c => (this.phoneCodeForm = c)}>
                                            <label className="mb-1">
                                                کد تایید پیامک شده به {this.state.login_values.phone} را وارد کنید.
                                                <Button onClick={() => this.stepBack()}>تغییر شماره</Button>
                                            </label>
                                            <AvGroup className="input-wrapper">
                                                <AvField
                                                    name="code"
                                                    type="text"
                                                    dir="ltr"
                                                    value=''
                                                    className="form-control phone-input"
                                                    onChange={this.editForm}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'الزامی است'}
                                                    }}
                                                    autoComplete="off"></AvField>
                                                <div
                                                    // style={{
                                                    //     "position": "absolute",
                                                    //     "left": 0,
                                                    //     "top": "-1rem"
                                                    // }}
                                                    style={{"margin-bottom": "1.5rem", "height": "37.5px"}}
                                                    className="px-0 d-flex justify-content-betweeen">
                                                    {this.state.reSendVerifyEnabled ?
                                                        <button
                                                            className="btn btn-success resend-btn"
                                                            onClick={this.resendCode}>
                                                            {this.state.isLoading ?
                                                                <>
                                                                    <Spinner color="primary"
                                                                             className="reload-spinner"/>
                                                                </>
                                                                :
                                                                <>
                                                                    <small style={{"margin-left": "0.5rem"}}
                                                                    >
                                                                        ارسال مجدد
                                                                    </small>
                                                                    <i className="fas fa-redo"/>
                                                                </>
                                                            }

                                                        </button>
                                                        :
                                                        <div
                                                            className="h-100 timer">
                                                            {this.state.showTime}
                                                        </div>
                                                    }
                                                </div>
                                            </AvGroup>

                                            <Button className="submit-login" type="submit">
                                                {this.state.isLoading ?
                                                    <>
                                                        <Spinner color="primary" className="reload-spinner"/>
                                                    </>
                                                    :
                                                    <>
                                                        ثبت و ورود
                                                    </>
                                                }

                                            </Button>
                                        </AvForm> : <AvForm onSubmit={this.register} className="position-relative"
                                                            ref={c => (this.infoForm = c)}>
                                            <label className="mb-1">
                                                نام
                                            </label>
                                            <AvGroup className="input-wrapper">
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    dir="rtl"
                                                    value=''
                                                    className="form-control"
                                                    onChange={this.editForm}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'الزامی است'}
                                                    }}
                                                    autoComplete="off"></AvField>
                                            </AvGroup>
                                            <label className="mb-1">
                                                نام خانوادگی
                                            </label>
                                            <AvGroup className="input-wrapper">
                                                <AvField
                                                    name="lastname"
                                                    type="text"
                                                    dir="rtl"
                                                    value=''
                                                    className="form-control"
                                                    onChange={this.editForm}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'الزامی است'}
                                                    }}
                                                    autoComplete="off"></AvField>
                                            </AvGroup>
                                            <div
                                                className="d-flex justify-content-start flex-wrap align-items-center">
                                                <label className="mb-1">
                                                    جنسیت
                                                </label>
                                                <Radio
                                                    name="gender"
                                                    label="زن"
                                                    className="mr-1"
                                                    defaultChecked={this.state.radioSelect === 0}
                                                    onClick={() => this.onClickRadio(0)}
                                                />
                                                <Radio
                                                    name="gender"
                                                    label="مرد"
                                                    defaultChecked={this.state.radioSelect === 1}
                                                    onClick={() => this.onClickRadio(1)}
                                                />
                                            </div>
                                            {/*<AvRadioGroup name="gender" label="جنسیت" required*/}
                                            {/*              errorMessage="انتخاب یک گزینه الزامی است.">*/}
                                            {/*    <AvRadio label="زن" value={0}/>*/}
                                            {/*    <AvRadio label="مرد" value={1}/>*/}
                                            {/*</AvRadioGroup>*/}
                                            <Button className="submit-login" type="submit">
                                                {this.state.isLoading ?
                                                    <>
                                                        <Spinner color="primary" className="reload-spinner"/>
                                                    </>
                                                    :
                                                    <> ثبت نام</>
                                                }

                                            </Button>
                                        </AvForm>}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                {this.state.login_step === 0 ?
                                    <AvForm onSubmit={this.checkLogin} ref={c => (this.emailForm = c)}>
                                        <label className="mb-1">
                                            ایمیل خود را وارد کنید.
                                        </label>
                                        <AvGroup className="input-wrapper">
                                            <AvField
                                                name="email"
                                                type="email"
                                                dir="ltr"
                                                value={this.state.login_values.email}
                                                className="form-control"
                                                onChange={this.editForm}
                                                validate={{
                                                    required: {value: true, errorMessage: 'الزامی است'}
                                                }}
                                                autoComplete="off"></AvField>
                                        </AvGroup>
                                        <Button className="submit-login" type="submit">
                                            {this.state.isLoading ?
                                                <>
                                                    <Spinner color="primary" className="reload-spinner"/>
                                                </>
                                                :
                                                <>
                                                    دریافت کد تایید
                                                </>
                                            }


                                        </Button>
                                    </AvForm> : this.state.login_step === 1 ?
                                        <AvForm onSubmit={this.login} className="position-relative"
                                                ref={c => (this.emailCodeForm = c)}>
                                            <label className="mb-1">
                                                کد تایید ایمیل شده {this.state.login_values.email} را وارد کنید.
                                                <Button onClick={() => this.stepBack()}>تغییر ایمیل</Button>
                                            </label>
                                            <AvGroup className="input-wrapper">
                                                <AvField
                                                    name="code"
                                                    type="text"
                                                    dir="ltr"
                                                    value=''
                                                    className="form-control phone-input"
                                                    onChange={this.editForm}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'الزامی است'}
                                                    }}
                                                    autoComplete="off"></AvField>
                                                <div
                                                    // style={{
                                                    //     "position": "absolute",
                                                    //     "left": 0,
                                                    //     "top": "-1rem"
                                                    // }}
                                                    style={{"margin-bottom": "1.5rem", "height": "37.5px"}}
                                                    className="px-0 d-flex justify-content-betweeen">
                                                    {this.state.reSendVerifyEnabled ?
                                                        <button
                                                            className="btn btn-success resend-btn"
                                                            onClick={this.resendCode}>
                                                            {this.state.isLoading ?
                                                                <>
                                                                    <Spinner color="primary"
                                                                             className="reload-spinner"/>
                                                                </>
                                                                :
                                                                <>
                                                                    <small
                                                                        style={{"margin-left": "0.5rem"}}
                                                                    >ارسال
                                                                        مجدد</small>
                                                                    <i className="fas fa-redo"/>
                                                                </>
                                                            }


                                                        </button>
                                                        :
                                                        <div
                                                            className="h-100 timer">
                                                            {this.state.showTime}
                                                        </div>
                                                    }
                                                </div>
                                            </AvGroup>

                                            <Button className="submit-login" type="submit">
                                                {this.state.isLoading ?
                                                    <>
                                                        <Spinner color="primary" className="reload-spinner"/>
                                                    </>
                                                    :
                                                    <>
                                                        ثبت و ورود
                                                    </>
                                                }
                                            </Button>
                                        </AvForm> : <AvForm onSubmit={this.register} className="position-relative"
                                                            ref={c => (this.infoForm = c)}>
                                            <label className="mb-1">
                                                نام
                                            </label>
                                            <AvGroup className="input-wrapper">
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    dir="rtl"
                                                    value=''
                                                    className="form-control"
                                                    onChange={this.editForm}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'الزامی است'}
                                                    }}
                                                    autoComplete="off"></AvField>
                                            </AvGroup>
                                            <label className="mb-1">
                                                نام خانوادگی
                                            </label>
                                            <AvGroup className="input-wrapper">
                                                <AvField
                                                    name="lastname"
                                                    type="text"
                                                    dir="rtl"
                                                    value=''
                                                    className="form-control"
                                                    onChange={this.editForm}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'الزامی است'}
                                                    }}
                                                    autoComplete="off"></AvField>
                                            </AvGroup>
                                            <div
                                                className="d-flex justify-content-start flex-wrap align-items-center mt-1">
                                                <Radio
                                                    name="gender"
                                                    label="زن"
                                                    className="mr-1"
                                                    defaultChecked={this.state.radioSelect === 0}
                                                    onClick={() => this.onClickRadio(0)}
                                                />
                                                <Radio
                                                    name="gender"
                                                    label="مرد"
                                                    defaultChecked={this.state.radioSelect === 1}
                                                    onClick={() => this.onClickRadio(1)}
                                                />
                                            </div>
                                            <Button className="submit-login" type="submit">
                                                {this.state.isLoading ?
                                                    <>
                                                        <Spinner color="primary" className="reload-spinner"/>
                                                    </>
                                                    :
                                                    <>
                                                        ثبت نام
                                                    </>
                                                }
                                            </Button>
                                        </AvForm>}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </ModalBody>
        </Modal>
        const support_modal = <Modal size="md" className="support-modal" centered isOpen={this.state.support}
                                     toggle={() => this.toggleSupport()}>
            <ModalHeader>
                <div className="icon-wrapper"><LiveHelpIcon/></div>
            </ModalHeader>
            <ModalBody>
                <h4 className="text-center">پشتیبانی آکادمی یگانه</h4>
                <p className="text-center">برای ارتباط با ما بر روی پشتیبانی آکادمی یگانه کلیک(لمس) کنید</p>
                <div className="d-flex justify-content-center">
                    <a href="https://api.whatsapp.com/send/?phone=989925393467&text&app_absent=0"
                       target="_blank"
                       className="support-btn">
                        <div>
                            <img src={logo} alt="logo" width={50}/>
                            <span>پشتیبانی آکادمی یگانه</span>
                        </div>
                        <div>
                            <WhatsApp/>
                        </div>

                    </a>
                </div>
            </ModalBody>
        </Modal>

        const download_modal =
            <Modal size="md" centered isOpen={this.state.modal_download}
                   toggle={() => this.toggleModalDownload()}>
                <ModalHeader className="bg-primary d-flex justify-content-center">
                    دانلود اپلیکیشن مدرسه
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="d-flex justify-content-center">
                                <div className="my-auto mr-1 ml-1">
                                    Android
                                </div>
                                <img src={android} alt="android-logo" width="20px"/>
                            </div>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank"
                               className="mt-1 d-flex justify-content-center">
                                <img src={play} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank"
                               className="mt-1 d-flex justify-content-center">
                                <img src={bazaar} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank"
                               className="mt-1 d-flex justify-content-center">
                                <img src={myket} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="d-flex justify-content-center">
                                <div className="my-auto mr-1 ml-1">
                                    IOS
                                </div>
                                <img src={apple} alt="apple-logo" width="20px"/>
                            </div>
                            <a href=""
                               target="_blank" className="mt-1 d-flex justify-content-center">
                                <img src={sibiranilogobanner} className="app-download"
                                     alt="download-app"/>
                            </a>
                            <a href="" target="_blank" className="mt-1 d-flex justify-content-center">
                                <img src={IappsBadgeGray} className="app-download"
                                     alt="download-app"/>
                            </a>
                            <a href="" target="_blank" className="mt-1 d-flex justify-content-center">
                                <img src={anardoniBadgeDarkPersian} className="app-download"
                                     alt="download-app"/>
                            </a>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>;

        let is_home = this.props.location.pathname === "/";
        let is_about = this.props.location.pathname === "/about-us";
        let mainBackground = localStorage.getItem("background") !== "" ? localStorage.getItem("background") : defaultBackground;
        return (
            <>
                <BrowserView>
                    {/*{(is_home && mainBackground) &&
                    <div className="web-view" style={{
                        backgroundImage: `url(${mainBackground})`
                    }}>

                    </div>
                    }*/}
                    {/*{!is_home &&
                    <div className="quick-access-view">
                        <div className="quick-access">
                            <Router.NavLink to='/courses/offline' className="box offline">
                                <div className="text m-auto">
                                    <p>
                                        بسته های
                                    </p>
                                    <p>
                                        آموزشی
                                    </p>
                                </div>
                                <div className="icon my-auto">
                                    <img src={edu} alt=""/>
                                </div>
                            </Router.NavLink>
                            <Router.NavLink to='/courses/online' className="box online">
                                <div className="text m-auto">
                                    <p>
                                        دوره های
                                    </p>
                                    <p>
                                        آنلاین
                                    </p>
                                </div>
                                <div className="icon my-auto">
                                    <img src={edu_online} alt=""/>
                                </div>
                            </Router.NavLink>
                            <Router.NavLink to='/courses/insite' className="box InSite">
                                <div className="text m-auto">
                                    <p>
                                        کارگاههای
                                    </p>
                                    <p>
                                        حضوری
                                    </p>
                                </div>
                                <div className="icon my-auto">
                                    <img src={edu_insite} alt=""/>
                                </div>
                            </Router.NavLink>
                        </div>
                    </div>
                    }*/}
                    <main>
                        <ContextLayout.Provider
                            value={{
                                showLoginModal: bool => {
                                    this.setState({modal: bool})
                                },
                                showSupportModal: bool => {
                                    this.setState({support: bool})
                                }
                            }}>
                            <div className="wrapper">
                                <Header location={this.props.location}/>
                                {is_home || is_about ?
                                    <div className="content">
                                        {this.props.children}
                                    </div>
                                    :
                                    <div className=" content">
                                        {this.props.children}
                                    </div>
                                }

                            </div>
                        </ContextLayout.Provider>
                    </main>
                    <Footer/>
                </BrowserView>
                <MobileView viewClassName="mobile-view">
                    <main>
                        <ContextLayout.Provider
                            value={{
                                showLoginModal: bool => {
                                    this.setState({modal: bool})
                                },
                                showSupportModal: bool => {
                                    this.setState({support: bool})
                                }
                            }}>
                            <div className="wrapper">
                                <Header modalToggler={() => this.toggleModal()}/>
                                <div className="content">
                                    {this.props.children}
                                </div>
                            </div>
                        </ContextLayout.Provider>
                        <div className="side">
                            <div className="d-flex">
                                <div className="text-right"
                                     style={{margin: '0 0.25rem', display: 'grid', justifyItems: 'center'}}>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </main>
                </MobileView>

                {login_modal}
                {support_modal}
                {download_modal}
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth
    }
}
export
{
    PageLayout, ContextLayout
}
export default connect(mapStateToProps,
    {
        LoginManual, VerifyManual, logout, RegisterManual
    }
)(PageLayout)
