import React, {useEffect, useState} from "react"
import classnames from "classnames"
import {useSelector} from "react-redux"
import {history} from "../../../history";

const Limit = true;
const LimitLevels = 4;
/**
 * menu type (mega-menu,normal-menu)
 **/
const TypeOfMenu = "mega-menu";
const Menu = () => {
    const [menu, setMenu] = useState(localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : []);
    const menuUpdate = useSelector(state => state.UI.menu);
    const MenuType = {
        Category: 0,
        Link: 1
    }
    const MenuOpen = {
        InTab: 0,
        NewTab: 1
    }
    const MenuStatus = {
        Active: 0,
        UnClickable: 1,
        Invisible: 2
    }
    const TypeOfMenuCondition = {
        megaMenu: "mega-menu",
        normalMenu: "normal-menu"
    }

    useEffect(() => {
        if (menuUpdate)
            setMenu(menuUpdate)
    }, [menuUpdate])


    const hasChild = (id) => {
        return menu.filter(f => f.parentId === id).length > 0
    }

    const isValidUrl = urlString => {
        let url;
        try {
            url = new URL(urlString);
        } catch (e) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    const handleUrl = (item) => {
        switch (parseInt(item.type)) {
            case MenuType.Category:
                history.push(`/courses/${item.category.value}`)
                break;
            case MenuType.Link:
                if (isValidUrl(item.url)) {
                    switch (parseInt(item.openIn)) {
                        case MenuOpen.InTab:
                            window.location = item.url;
                            break;
                        case MenuOpen.NewTab:
                            window.open(item.url, "_blank");
                            break;
                    }
                } else {
                    history.push(item.url);
                }
                break;
        }
    }

    const renderSubMenu = (parent_id = "main", limit = 1) => {
        return menu.filter((item) => item.parentId === parent_id).sort((a, b) => a.index - b.index).map((menu_item, index) => {
            let view;
            switch (TypeOfMenu) {
                case TypeOfMenuCondition.megaMenu:
                    view = (
                        <>
                            <div className={classnames("menu-item", {
                                'has-child': hasChild(menu_item.id),
                                "menu-item": parent_id === "main",
                                'lock': menu_item.status === MenuStatus.UnClickable
                            })} onClick={menu_item.status === MenuStatus.UnClickable ? () => {
                            } : () => handleUrl(menu_item)}>
                                <div className={classnames("title ml-1", {'Open': menu_item.collapse})}>
                                    {menu_item.title}
                                </div>
                            </div>
                            {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
                            <div className={classnames("", {
                                "sub-menu": limit < 2,
                                "sub-menu-mega": limit >= 2
                            })}>
                                {renderSubMenu(menu_item.id, limit + 1)}
                            </div>
                            }
                        </>
                    )
                    break;
                case TypeOfMenuCondition.normalMenu:
                    view = (
                        <>
                            <div className={classnames("menu-item", {
                                'has-child': hasChild(menu_item.id),
                                "menu-item": parent_id === "main",
                                'lock': menu_item.status === MenuStatus.UnClickable
                            })} onClick={menu_item.status === MenuStatus.UnClickable ? () => {
                            } : () => handleUrl(menu_item)}>
                                <div className={classnames("child-title title", {'Open': menu_item.collapse})}>
                                    {menu_item.title}
                                </div>
                            </div>
                            {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
                            <div className={classnames("sub-menu")}>
                                {renderSubMenu(menu_item.id)}
                            </div>
                            }
                        </>
                    )
                    break;
            }
            if (menu_item.status !== MenuStatus.Invisible)
                return (
                    <div className="box-item">
                        {view}
                    </div>
                )
        });
    };

    return menu?.length > 0 &&
        <div className={TypeOfMenu}>
            {renderSubMenu()}
        </div>
};

export default Menu;
