import React, {useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import classnames from "classnames";
import {useSelector} from "react-redux";
import {history} from "../../../history";

const Limit = true;
const LimitLevels = 4;
const MobileMenu = ({toggleMenu}) => {
    const [menu, setMenu] = useState(localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : []);
    const menuUpdate = useSelector(state => state.UI.menu);
    const MenuType = {
        Category: 0,
        Link: 1
    }
    const MenuOpen = {
        InTab: 0,
        NewTab: 1
    }
    const MenuStatus = {
        Active: 0,
        UnClickable: 1,
        Invisible: 2
    }

    useEffect(() => {
        setMenu(menuUpdate)
    }, [menuUpdate])

    const toggle = (menu_id) => {
        let index = menu.findIndex(f => f.id === menu_id);
        if (index >= 0) menu[index].collapse = !menu[index].collapse;
        setMenu([...menu]);
    }

    const hasChild = (id) => {
        return menu.filter(f => f.parentId === id).length > 0
    }

    const isValidUrl = urlString => {
        let url;
        try {
            url = new URL(urlString);
        } catch (e) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    const handleUrl = (item) => {
        switch (parseInt(item.type)) {
            case MenuType.Category:
                toggleMenu();
                history.push(`/courses/${item.category.value}`)
                break;
            case MenuType.Link:
                if (isValidUrl(item.url)) {
                    switch (parseInt(item.openIn)) {
                        case MenuOpen.InTab:
                            window.location = item.url;
                            break;
                        case MenuOpen.NewTab:
                            toggleMenu();
                            window.open(item.url, "_blank");
                            break;
                    }
                } else {
                    toggleMenu();
                    history.push(item.url);
                }
                break;
        }
    }

    const renderSubMenu = (parent_id = "main", limit = 1) => {
        return menu.filter((item) => item.parentId === parent_id).sort((a, b) => a.index - b.index).map((menu_item, index) => {
            if (menu_item.status !== MenuStatus.Invisible)
                return (
                    <div key={index} className={classnames("", {
                        "menu-mobile-main": parent_id === "main",
                    })}>
                        <div className={classnames("menu-mobile-item", {
                            'lock': menu_item.status === MenuStatus.UnClickable
                        })}>
                            <div className={classnames("title", {'Open': menu_item.collapse})}
                                 onClick={menu_item.status === MenuStatus.UnClickable ? () => {
                                 } : () => handleUrl(menu_item)}>
                                {menu_item.title}
                            </div>
                            {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
                            <div className="icon" onClick={() => toggle(menu_item.id)}>
                                <i className={classnames("my-auto float-right", {
                                    'fa fa-angle-left': !menu_item.collapse,
                                    'fa fa-angle-left open': menu_item.collapse
                                })}
                                > </i>
                            </div>
                            }
                        </div>
                        {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
                        <Collapse className={classnames("menu-mobile-bg m-0")} isOpen={menu_item.collapse}>
                            {Limit ?
                                limit < LimitLevels &&
                                renderSubMenu(menu_item.id, (limit + 1))
                                :
                                renderSubMenu(menu_item.id)
                            }
                        </Collapse>
                        }
                    </div>
                )
        });
    };

    return menu?.length > 0 && renderSubMenu()
};

export default MobileMenu;

/*
class test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : [],
            limit: true,
            limit_levels: 3
        }
    }

    componentDidMount() {
        console.log(this.state.menu)
        //this.createCollSetting(this.state.menu)
    }

    /!*createCollSetting(menu) {
        let parent = this;
        let collapseSetting = parent.state.collapseSetting;
        menu.map(function (item, i) {
            let obj = {
                collapse_id: item.menu_id,
                isOpen: false
            }
            collapseSetting.push(obj);
            item.children && parent.createCollSetting(item.children);
        });
        parent.setState({
            collapseSetting: collapseSetting
        });
    }*!/

    toggle = (menu_id) => {
        let menuTemp = this.state.menu;
        let menuItem = menuTemp.find(f => f.id === menu_id);
        menuItem.collapse = !menuItem.collapse;
        this.setState({
            menu: [...menuTemp]
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.UI.menu !== prevProps.UI.menu)) {
            if (this.props.UI.menu)
                this.setState({
                    menu: this.props.UI.menu
                })
        }
    }

    /!*renderMenu = () => {
        let parent = this;
        return this.state.menu.map((menu_item, index) => {
                return (
                    <div key={index} className={classnames("menu-mobile-main 1")}>
                        {(menu_item.canHaveChild) ?
                            <>
                                <div className="m-0" onClick={() => parent.toggle(menu_item.id)}>
                                    <div className={classnames("menu-mobile-item", {
                                            'Open': menu_item.collapse
                                        })}
                                        onClick={!menu_item.link ? (e) => {
                                            e.preventDefault();
                                        } : () => {}}>
                                            <span onClick={menu_item.link ? () => {
                                                this.props.toggle();
                                                history.push(menu_item.link)
                                            } : () => parent.toggle(menu_item.menu_id)}>
                                                 {menu_item.title}
                                            </span>
                                        {menu_item.collapse ?
                                            <i onClick={() => parent.toggle(menu_item.menu_id)}
                                               className="fa fa-angle-up my-auto mr-1 ml-1 float-right"></i>
                                            :
                                            <i onClick={() => parent.toggle(menu_item.menu_id)}
                                               className="fa fa-angle-down my-auto mr-1 ml-1 float-right"></i>
                                        }
                                    </div>
                                </div>
                                <Collapse className={classnames("menu-mobile-bg m-0")} isOpen={menu_item.collapse}>
                                    {parent.renderSubMenu(menu_item.children)}
                                </Collapse>
                            </>
                            :
                            <NavLink className="menu-mobile-item" onClick={() => this.props.toggle()}
                                     to={menu_item.url}>
                                {menu_item.title}
                            </NavLink>
                        }
                    </div>
                )
            }
        )
    }*!/

    renderSubMenu = (menu, limit = 1, filter_id = "main") => {
        let parent = this;
        return menu.filter(filter_id).map((menu_item, index) => {
                //console.log(menu_item)
                return (
                    <div key={index} className={classnames("", {
                        'menu-mobile-main': limit === 1,
                        'mx-1': limit >= 2,
                        'mt-1': limit > 1
                    })}>
                        {(menu_item.canHaveChild) ?
                            <>
                                <div className="m-0"
                                     onClick={() => parent.toggle(menu_item.id)}>
                                    <div //to={menu_item.link}
                                        className={classnames("menu-mobile-item", {
                                            'Open': menu_item.collapse
                                        })}
                                        onClick={!menu_item.link ? (e) => {
                                            e.preventDefault();
                                        } : () => {
                                            //parent.toggle(menu_item.menu_id)
                                            //history.push(menu_item.link)
                                        }}>
                                            <span onClick={menu_item.link ? () => {
                                                this.props.toggle();
                                                history.push(menu_item.link)
                                            } : () => parent.toggle(menu_item.menu_id)}>
                                                 {menu_item.title}
                                            </span>
                                        {parent.state.limit ?
                                            limit < parent.state.limit_levels &&
                                            <>
                                                {menu_item.collapse ?
                                                    <i onClick={() => parent.toggle(menu_item.menu_id)}
                                                       className="fa fa-angle-up my-auto mr-1 ml-1 float-right"></i>
                                                    :
                                                    <i onClick={() => parent.toggle(menu_item.menu_id)}
                                                       className="fa fa-angle-down my-auto mr-1 ml-1 float-right"></i>
                                                }
                                            </>
                                            :
                                            <>
                                                {menu_item.collapse ?
                                                    <i onClick={() => parent.toggle(menu_item.menu_id)}
                                                       className="fa fa-angle-up my-auto mr-1 ml-1 float-right"></i>
                                                    :
                                                    <i onClick={() => parent.toggle(menu_item.menu_id)}
                                                       className="fa fa-angle-down my-auto mr-1 ml-1 float-right"></i>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                <Collapse className={classnames("m-0", {
                                    'menu-mobile-bg': limit === 1
                                })} isOpen={menu_item.collapse}>
                                    {parent.state.limit ?
                                        limit < parent.state.limit_levels &&
                                        <span>
                                                {parent.renderSubMenu(menu_item.children, (limit + 1))}
                                            </span>
                                        :
                                        <>
                                            {parent.renderSubMenu(menu_item.children)}
                                        </>
                                    }
                                </Collapse>

                            </>
                            :
                            <>
                                {menu_item.category_id ?
                                    <NavLink className="menu-mobile-item" onClick={() => this.props.toggle()}
                                             to={menu_item.url}>
                                        {menu_item.title}
                                    </NavLink>
                                    :
                                    <NavLink className="menu-mobile-item" onClick={() => this.props.toggle()}
                                             to={menu_item.url}>
                                        {menu_item.title}
                                    </NavLink>
                                }
                            </>
                        }
                    </div>
                )
            }
        )
    }

    render() {
        let menu = this.renderSubMenu(this.state.menu);
        return (
            <>
                <div className="menu-mobile-main">
                    <NavLink to="/"
                             className={classnames("menu-mobile-item mb-0")}
                             onClick={(e) => {
                                 this.props.toggle();
                                 e.stopPropagation()
                             }}>
                        صفحه اصلی
                    </NavLink>
                </div>
                {menu}
                <div className="menu-mobile-main">
                    <NavLink to="/contact-us"
                             className={classnames("menu-mobile-item mb-0")}
                             onClick={(e) => {
                                 this.props.toggle();
                                 e.stopPropagation()
                             }}>
                        تماس با ما
                    </NavLink>
                </div>
                <div className="menu-mobile-main">
                    <NavLink to="/about-us"
                             className={classnames("menu-mobile-item mb-0")}
                             onClick={(e) => {
                                 this.props.toggle();
                                 e.stopPropagation()
                             }}>
                        درباره ما
                    </NavLink>
                </div>
                <div className="menu-mobile-main">
                    <NavLink to="/articles"
                             className={classnames("menu-mobile-item mb-0")}
                             onClick={(e) => {
                                 this.props.toggle();
                                 e.stopPropagation()
                             }}>
                        مقالات
                    </NavLink>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        UI: state.UI
    }
}
export default connect(mapStateToProps)(test)
*/
