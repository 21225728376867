import React from "react";
import {isBrowser} from "react-device-detect";
import {LocationCity, WhatsApp} from "@material-ui/icons";
import {Instagram, Mail} from "react-feather";
import {Col, NavLink, Row} from "reactstrap";
import {Tooltip} from "@material-ui/core";
import fanni from "../../../assets/img/logo/Fanni.png";
import kasbokar from "../../../assets/img/logo/kasbokar.png"
import {Clock, Link2, Phone, PhoneCall, Share2, Eye, Star} from "react-feather";
import {FormattedMessage} from "react-intl";
import * as PropTypes from "prop-types";
import logo from "../../../assets/img/logo/logo.png";
import {MapRounded} from "@material-ui/icons";
import {connect} from "react-redux";
import {LoginManual, logout, RegisterManual, VerifyManual} from "../../../redux/actions/auth/loginActions";
import {PageLayout} from "../../../layouts/PageLayout";


function VscWorkspaceTrusted(props) {
    return null;
}

VscWorkspaceTrusted.propTypes = {className: PropTypes.string};

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            whatsapp: false, enamad: true,samandehi:true
        }
    }

    componentDidMount() {
    }

    support = () => {
        this.setState({
            whatsapp: !this.state.whatsapp,
        })
    }

    render() {
        return (<div className="web-footer">
            <div className="footer-container">
                {isBrowser ? <>
                    <Row style={{alignItems: "center"}}>
                        <Col sm={12} md={9}>
                            {/*   <div className="trust-box d-flex">
                                <h3>
                                    <Clock className="mr-1"/>
                                    اعتبار
                                </h3>
                            </div>*/}
                            <div className="d-flex justify-content-center nemad">
                                {this.state.enamad ? <div className="m-auto">
                                    <a referrerPolicy="origin" target="_blank"
                                       href="https://trustseal.enamad.ir/?id=318214&amp;Code=sLuBDa2yr3l6AoafaRdq">
                                        <img
                                            referrerPolicy="origin"
                                            id="sLuBDa2yr3l6AoafaRdq"
                                            src="https://Trustseal.eNamad.ir/logo.aspx?id=318214&amp;Code=sLuBDa2yr3l6AoafaRdq"
                                            alt="" style={{cursor: "pointer" , width: "130px"}}
                                        />
                                    </a>
                                </div> : null}
                                {this.state.samandehi ? <div className="m-auto">
                                    <img referrerPolicy='origin' id='rgvjwlaooeuknbqeoeuksizp'
                                         style={{cursor: "pointer" ,width: "130px"}}
                                         onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=348289&p=xlaoaodsmcsiuiwkmcsipfvl", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")}
                                         alt='logo-samandehi'
                                         src='https://logo.samandehi.ir/logo.aspx?id=348289&p=qftishwlaqgwodrfaqgwbsiy'/>
                                </div> : null}
                                <div className="m-auto">
                                    <a target="_blank"
                                       href="https://irantvto.ir/">
                                        <img
                                            src={fanni}
                                            alt="" style={{cursor: "pointer", width: "130px"}}

                                        />
                                    </a>
                                </div>
                                <div className="m-auto">
                                    <a target="_blank"
                                       href="https://ecunion.ir/">
                                        <img
                                            src={kasbokar}
                                            alt="" style={{cursor: "pointer", width: "130px"}}

                                        />
                                    </a>
                                </div>
                                <div className="m-auto">
                                    <a target="_blank"
                                       href="/">
                                        <img src={logo} width={100}/>

                                    </a>
                                </div>

                            </div>
                        </Col>
                        <Col sm={12} md={3} className="pt-1 m-auto">
                            <div className="map-footer h-100">
                                <iframe src="https://balad.ir/embed?p=60bwusqaQvKxck"
                                        title="مشاهده «آموزشگاه طب سنتی و داروسازی گیاهی علم کهن» روی نقشه بلد"
                                        width="100%"
                                    // height="450"
                                        frameBorder="0"
                                        allowFullScreen="" aria-hidden="false" tabIndex="0">
                                </iframe>
                            </div>
                        </Col>
                    </Row>
                    <Row className="p-1 w-100">
                        <Col sm={12} md={3}>
                            <div className="distance d-flex flex-column">
                                <div className="address-box d-flex">
                                    <h3>
                                        <Eye className="mr-1"/>
                                        <FormattedMessage id="Visitors"/>
                                    </h3>
                                </div>
                                {this.props.UI?.visitors &&
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>بازدید امروز</div>
                                        <div>{this.props.UI.visitors?.count_per_day}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>بازدید هفته گذشته</div>
                                        <div>{this.props.UI.visitors?.count_per_week}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>بازدید ماه گذشته</div>
                                        <div>{this.props.UI.visitors?.count_per_month}</div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                            <div>کل بازدید ها</div>
                                            <div>{this.props.UI.visitors?.total_count}</div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div>بازدید های یکتا</div>
                                            <div>{this.props.UI.visitors?.total_unique_count}</div>
                                        </div>
                                    </>
                                }
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="address-box d-flex">
                                <h3>
                                    <PhoneCall className="mr-1"/>
                                    <FormattedMessage id="TellUs"/>
                                </h3>
                            </div>
                            <div className="pt-1 ">
                                <div className="d-flex">
                                    <div className='icon-call'>
                                        <Phone size={20} className="my-auto"/>
                                    </div>
                                    {/*<Phone size={28} className="mr-1 my-auto"/>*/}
                                    <NavLink href="tel:09101800448" target="_blank">
                                        <span>09101800448</span>
                                    </NavLink>
                                </div>
                                <div className="d-flex">
                                    <div className='icon-call'>
                                        <Phone size={20} className="my-auto"/>
                                    </div>
                                    <NavLink href="tel:02632404166" target="_blank">
                                        <span>02632404166</span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="d-flex ">
                                <div className='icon-call'>
                                    <MapRounded size={25} className=""/>
                                </div>
                                <p className="text-justify " style={{marginRight:'7px'}}>
                                    استان البرز- کرج- چهارراه امام خمینی (میدان کرج)- ابتدای خیابان شهید بهشتی-
                                    خیابان
                                    دکتر همایون- روبروی هتل سامان- کوچه سهند (نهر آب)- پلاک7- طبقه دوم
                                </p>
                            </div>

                        </Col>
                        <Col sm={12} md={3}>
                            <div className="social-box my-auto">
                                <h3>
                                    <Share2 className="mr-1"/>
                                    <FormattedMessage id="socialMedia"/>
                                </h3>
                            </div>
                            <div className="social-media d-flex flex-wrap pt-1">
                                <div className="whatsapp-icon">
                                    <NavLink
                                        href="https://api.whatsapp.com/send/?phone=989101800448&text&app_absent=0"
                                        target="_blank">
                                        <WhatsApp style={{fontSize: '30px'}} className="my-auto"/>
                                    </NavLink>
                                </div>

                                <div className="mail-icon">
                                    <NavLink href="mailto:elm.kohan@yahoo.com" target="_blank">
                                        <Mail size={28} className="my-auto"/>
                                    </NavLink>
                                </div>
                                <div className="instagram-icon">
                                    <NavLink href="https://www.instagram.com/elm.kohan/" target="_blank">
                                        <Instagram size={28} className=" my-auto"/>
                                    </NavLink>
                                </div>


                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="relative-links-box my-auto">
                                <h3>
                                    <Link2 className="mr-1"/>
                                    لینک های مرتبط
                                </h3>
                            </div>
                            <div className="relative-box d-flex flex-column flex-wrap pt-1">
                                <div>
                                    <a href="http://karaj.alborz.pnu.ac.ir/portal/home/" target="_blank">دانشگاه
                                        پیام نور کرج</a>
                                </div>
                                <div>
                                    <a href="https://karaj.iau.ir/fa" target="_blank">دانشگاه آزاد کرج</a>
                                </div>
                                <div>
                                    <a href="https://treatment.sbmu.ac.ir/"
                                       target="_blank">دفتر طب ایرانی</a>
                                </div>
                                <div>
                                    <a href="https://behdasht.gov.ir/" target="_blank">وزارت بهداشت</a>
                                </div>
                                <div>
                                    <a href="https://www.fda.gov.ir/" target="_blank">سازمان غذا و دارو</a>
                                </div>
                                <div>
                                    <a href="https://irantvto.ir/" target="_blank">سازمان فنی حرفه ای</a>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <div className="copyright p-1 w-100 m-auto">

                        <p className="my-auto">
                            تمامی حقوق این وب سایت برای آکادمی علم کهن محفوظ می باشد.
                        </p>
                        <a href="https://barantm.ir" target='_blank' rel='noopener' className="my-auto">
                            <p className="m-0">
                                طراحی و تولید توسط شرکت برنامه نویسی باران
                            </p>
                        </a>

                    </div>
                </> : <>
                    <Row>
                        <Col md={6} className="d-md-block d-sm-none pt-1 m-auto text-center logo-col">
                            <img src={logo} width={200}/>
                        </Col>
                        <Col sm={12} md={6} className="pt-1 m-auto">
                            <div className="map-footer h-100 mb-2">
                                <iframe src="https://balad.ir/embed?p=60bwusqaQvKxck"
                                        title="مشاهده «آموزشگاه طب سنتی و داروسازی گیاهی علم کهن» روی نقشه بلد"
                                        width="100%"
                                    // height="450"
                                        frameBorder="0"
                                        allowFullScreen="" aria-hidden="false" tabIndex="0">
                                </iframe>
                            </div>
                        </Col>
                    </Row>
                    <div className="mx-1">
                        <div className="address-box d-flex">
                            <h3>
                                <PhoneCall className="mr-1"/>
                                <FormattedMessage id="TellUs"/>
                            </h3>
                        </div>
                        <div className="pt-1 ">
                            <div className="d-flex">
                                <div className='icon-call'>
                                    <Phone size={20} className="my-auto"/>
                                </div>
                                {/*<Phone size={28} className="mr-1 my-auto"/>*/}
                                <NavLink href="tel:09101800448" target="_blank">
                                    <span>09101800448</span>
                                </NavLink>
                            </div>
                            <div className="d-flex">
                                <div className='icon-call'>
                                    <Phone size={20} className="my-auto"/>
                                </div>
                                <NavLink href="tel:02632404166" target="_blank">
                                    <span>02632404166</span>
                                </NavLink>
                            </div>
                        </div>

                        <div className="d-flex ">
                            <div className='icon-call'>
                                <MapRounded size={25} className=""/>
                            </div>
                            <p className="text-justify " style={{marginRight:'7px'}}>
                                استان البرز- کرج- چهارراه امام خمینی (میدان کرج)- ابتدای خیابان شهید بهشتی-
                                خیابان
                                دکتر همایون- روبروی هتل سامان- کوچه سهند (نهر آب)- پلاک7- طبقه دوم
                            </p>
                        </div>
                        <div className="mt-2">
                            <div className="relative-links-box my-auto">
                                <h3>
                                    <Link2 className="mr-1"/>
                                    لینک های مرتبط
                                </h3>
                            </div>
                            <div className="relative-box d-flex flex-column flex-wrap pt-1">
                                <div>
                                    <a href="http://karaj.alborz.pnu.ac.ir/portal/home/" target="_blank">دانشگاه
                                        پیام نور کرج</a>
                                </div>
                                <div>
                                    <a href="https://karaj.iau.ir/fa" target="_blank">دانشگاه آزاد کرج</a>
                                </div>
                                <div>
                                    <a href="https://treatment.sbmu.ac.ir/"
                                       target="_blank">دفتر طب ایرانی</a>
                                </div>
                                <div>
                                    <a href="https://behdasht.gov.ir/" target="_blank">وزارت بهداشت</a>
                                </div>
                                <div>
                                    <a href="https://www.fda.gov.ir/" target="_blank">سازمان غذا و دارو</a>
                                </div>
                                <div>
                                    <a href="https://irantvto.ir/" target="_blank">سازمان فنی حرفه ای</a>
                                </div>

                            </div>
                        </div>
                        <div className="distance d-flex flex-column mt-2">
                            <h4>
                                <Eye/> بازدیدکنندگان
                            </h4>
                            {this.props.UI?.visitors &&
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>بازدید امروز</div>
                                        <div>{this.props.UI.visitors?.count_per_day}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>بازدید هفته گذشته</div>
                                        <div>{this.props.UI.visitors?.count_per_week}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>بازدید ماه گذشته</div>
                                        <div>{this.props.UI.visitors?.count_per_month}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                        <div>کل بازدید ها</div>
                                        <div>{this.props.UI.visitors?.total_count}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <div>بازدید های یکتا</div>
                                        <div>{this.props.UI.visitors?.total_unique_count}</div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="mt-2">
                            <div className="social-box my-auto">
                                <h3>
                                    <Share2 className="mr-1"/>
                                    <FormattedMessage id="socialMedia"/>
                                </h3>
                            </div>
                            <div className="social-media d-flex flex-wrap pt-1">
                                <div className="whatsapp-icon">
                                    <NavLink
                                        href="https://api.whatsapp.com/send/?phone=989101800448&text&app_absent=0"
                                        target="_blank">
                                        <WhatsApp style={{fontSize: '30px'}} className="my-auto"/>
                                    </NavLink>
                                </div>

                                <div className="mail-icon">
                                    <NavLink href="mailto:elm.kohan@yahoo.com" target="_blank">
                                        <Mail size={28} className="my-auto"/>
                                    </NavLink>
                                </div>
                                <div className="instagram-icon">
                                    <NavLink href="https://www.instagram.com/elm.kohan/" target="_blank">
                                        <Instagram size={28} className=" my-auto"/>
                                    </NavLink>
                                </div>


                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="address-box d-flex">
                                <h3>
                                    <Star className="mr-1"/>
                                    اعتبار
                                </h3>
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-center p-1 justify-content-center nemad">
                                {this.state.enamad ? <div className="m-auto">
                                    <a referrerPolicy="origin" target="_blank"
                                       href="https://trustseal.enamad.ir/?id=318214&amp;Code=sLuBDa2yr3l6AoafaRdq">
                                        <img
                                            referrerPolicy="origin"
                                            id="sLuBDa2yr3l6AoafaRdq"
                                            src="https://Trustseal.eNamad.ir/logo.aspx?id=318214&amp;Code=sLuBDa2yr3l6AoafaRdq"
                                            alt="" style={{cursor: "pointer" , width:"130px"}}
                                        />
                                    </a>
                                </div> : null}
                                {this.state.samandehi ? <div className="m-auto">
                                    <img referrerPolicy='origin' id='rgvjwlaooeuknbqeoeuksizp'
                                         style={{cursor: "pointer" ,width: "130px"}}
                                         onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=348289&p=xlaoaodsmcsiuiwkmcsipfvl", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")}
                                         alt='logo-samandehi'
                                         src='https://logo.samandehi.ir/logo.aspx?id=348289&p=qftishwlaqgwodrfaqgwbsiy'/>
                                </div> : null}
                                <div className="m-auto">
                                    <a target="_blank"
                                       href="https://irantvto.ir/">
                                        <img
                                            src={fanni}
                                            alt="" style={{cursor: "pointer", width: "130px"}}

                                        />
                                    </a>
                                </div>
                                <div className="m-auto">
                                    <a target="_blank"
                                       href="https://ecunion.ir/">
                                        <img
                                            src={kasbokar}
                                            alt="" style={{cursor: "pointer", width: "130px"}}

                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright pt-1 px-1">
                        <a href="https://barantm.ir" target='_blank' rel='noopener' className="my-auto">
                            <p className="m-0">
                                طراحی و تولید توسط شرکت برنامه نویسی باران
                            </p>
                        </a>
                        <p className="my-auto">
                            تمامی حقوق این وب سایت برای آکادمی علم کهن محفوظ می باشد.
                        </p>
                    </div>
                </>}
                {/*<div className="div-contact">
                    {this.state.whatsapp &&
                        <div className="hidden-support">
                            <div className="title-support">
                                <h2>پشتیبانی آکادمی</h2>
                                <span>برای برقراری ارتباط با پشتیبانی کلیک کنید.</span>
                            </div>
                            <div className="body-support">
                                <a href="https://wa.me/989127725099"
                                   className="d-flex justify-content-between align-items-center">
                                    <img src={logo} className="img-support"/>
                                    <span>پشتیبانی آموزشگاه</span>
                                    <WhatsApp className="color-support"/>
                                </a>
                            </div>
                        </div>
                    }
                    <button className="btn-contact" onClick={() => {
                        this.support()
                    }}>
                        {this.state.whatsapp ?
                            <Close/>
                            :
                            <WhatsApp/>

                        }

                    </button>
                </div>*/}
            </div>
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        UI: state.UI
    }
}
export default connect(mapStateToProps)(Footer)
