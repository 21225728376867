import React from "react";
import {connect} from "react-redux";
import {logout} from "../../../redux/actions/auth/loginActions";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import Menu from "components/Web/Headers/Menu"
import * as Icon from "react-feather";
import {ListGroup} from 'react-bootstrap';
import {NavLink as RNavLink} from "reactstrap";
import MobileNavbar from "./MobileMenuRender/Navbar";
import logo from "assets/img/logo/logo.png";
import PersonIcon from "@material-ui/icons/Person";
import {GetRequest} from "../../request";
import Notify from "components/Baran/noty/notify";
import {history} from "../../../history";
import {NavLink} from "react-router-dom";
import {Roles} from "../../roles/ProjectRole";
import {
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader, Row,
    UncontrolledDropdown
} from "reactstrap";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import {ContextLayout} from "../../../layouts/PageLayout";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import {CloseSharp, WhatsApp} from "@material-ui/icons";
import android from "../../../assets/img/downloads/android-logo.png";
import play from "../../../assets/img/downloads/androidPlay.png";
import bazaar from "../../../assets/img/downloads/bazaar.png";
import myket from "../../../assets/img/downloads/myket.png";
import apple from "../../../assets/img/icons/apple.png";
import sibiranilogobanner from "../../../assets/img/downloads/sibiranilogobanner.png";
import IappsBadgeGray from "../../../assets/img/downloads/IappsBadgeGray.png";
import anardoniBadgeDarkPersian from "../../../assets/img/downloads/anardoniBadgeDarkPersian.png";
import news1 from "../../../assets/img/pages/news-1.png";
import news2 from "../../../assets/img/pages/news-2.png";

import {
    ChevronsLeft,
    ChevronsRight,
    Instagram,
    Mail,
    MapPin,
    Phone,
    User
} from "react-feather";
import {Tooltip} from "@material-ui/core";


let searchDelay = 0;

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doesShowSearchBar: false,
            searchValue: "",
            modal: false,
            searchUrl: "/api/home/web/search",
            courses: [],
            course_categories: [],
            articles: [],
            article_categories: [],
            searchBox: false,
            dropdownUser: false,
            support: false,
            modal_download: false,
            mobileClick: false,
            quicknews: this.props.UI.quick_news,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.UI !== prevProps.UI)) {
            this.setState({
                quicknews: this.props.UI.quick_news
            })
        }
    }

    getData = () => {
        GetRequest(`${this.state.searchUrl}?s=${this.state.searchValue}`,
            {
                'Authorization': this.props.user.token,
            }, true).then((response) => {
            let result = response.data;
            if (result.ok) {
                result = result.data;
                this.setState({
                    courses: result.courses,
                    course_categories: result.course_categories,
                    articles: result.articles,
                    article_categories: result.article_categories
                })
            }
        }).catch(function (error) {
            Notify('error', 'top-right', "خطایی رخ داده است");
        });
    }

    showSearchBar = () => {
        this.setState({
            doesShowSearchBar: !this.state.doesShowSearchBar
        })
    }

    searchHandler = (e) => {
        clearTimeout(searchDelay)

        this.setState({
            searchValue: e.target.value,
            closeList: false
        })
        if (this.state.searchValue.length > 0) {
            searchDelay = setTimeout(async () => {
                this.getData();
            }, 800);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            history.push("/search?s=" + this.state.searchValue);
        }
    }

    closeResults = () => {
        this.setState({
            closeList: !this.state.closeList,
        })
    }

    goToPanel = () => {
        if (this.props.user.roles.includes(Roles.Student))
            history.push('/panel/my-courses')
        else if (this.props.user.roles.includes(Roles.Teacher))
            history.push('/panel/teacher-courses')
        else
            history.push('/panel/dashboard')
    }

    exit = () => {
        if (this.props.user.token)
            this.props.logout()
    }

    toggleSearch = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    }

    toggleDropdown = (bool) => {
        this.setState({
            dropdownUser: bool
        })
    }

    toggleSupport = () => {
        this.setState({
            support: !this.state.support
        })
    }

    toggleModalDownload = () => {
        this.setState({
            modal_download: !this.state.modal_download
        })
    }

    download = (url) => {
        window.open(url, "_blank")
    }

    render() {
        const searchbar =
            <>
                {
                    <div className="d-flex search-field">
                        {this.state.searchValue ?
                            <ListGroup className={this.state.closeList ? "d-none" : "search-results"}
                                       onMouseLeave={() => this.closeResults()}>
                                {this.state.courses.length > 0 ?
                                    <>
                                        <label className="d-flex justify-content-between">
                                            <span>دوره ها</span>
                                            <NavLink to={"/search?s=" + this.state.searchValue}
                                                     className="bg-transparent border-0">مشاهده بیشتر</NavLink>
                                        </label>
                                        {this.state.courses.map((item, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        className={`w-100 ${this.state.course_categories.length - 1 === index && "border-b"}`}>
                                                        <NavLink to={"/course/" + item.slug} className="w-100 d-block"
                                                                 key={item.course_id}>
                                                            <div className="d-flex align-items-flex">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src={item.image}
                                                                        alt=""/>
                                                                </div>
                                                                <span
                                                                    className="my-auto ml-1">{item.name}</span>
                                                            </div>
                                                        </NavLink>
                                                    </ListGroup.Item>
                                                )
                                            }
                                        )}
                                    </> : null
                                }
                                {this.state.articles.length > 0 ?
                                    <>
                                        <label className="d-flex justify-content-between">
                                            <span>مقاله ها</span>
                                            <NavLink to={"/search?s=" + this.state.searchValue}
                                                     className="bg-transparent border-0">مشاهده بیشتر</NavLink>
                                        </label>
                                        {this.state.articles.map((item, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        className={`w-100 ${this.state.course_categories.length - 1 === index && "border-b"}`}>
                                                        <NavLink to={"/article/" + item.slug} className="w-100 d-block"
                                                                 key={item.article_id}>
                                                            <div className="d-flex align-items-flex">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src={item.image}
                                                                        alt=""/>
                                                                </div>
                                                                <span
                                                                    className="my-auto ml-1">{item.title}</span>
                                                            </div>
                                                        </NavLink>
                                                    </ListGroup.Item>
                                                )
                                            }
                                        )}
                                    </> : null
                                }
                            </ListGroup> : null
                        }
                    </div>
                }
            </>

        const search =
            <>
                <Icon.Search onClick={this.toggleSearch} className="btn-search"/>
                <div className={classnames("web-search", this.state.searchBox ? "d-block" : "d-none")}>
                    <input
                        className="search-box"
                        type="text"
                        placeholder="جستجو..."
                        onKeyDown={this.handleKeyDown}
                        onMouseEnter={() => this.closeResults()}
                        onChange={(e) => this.searchHandler(e)}
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon2"
                    />
                </div>
            </>;

        const support_modal = <Modal size="md" className="support-modal" centered isOpen={this.state.support}
                                     toggle={() => this.toggleSupport()}>
            <ModalHeader>
                <div className="icon-wrapper"><LiveHelpIcon/></div>
            </ModalHeader>
            <ModalBody>
                <h4 className="text-center">پشتیبانی آکادمی یگانه</h4>
                <p className="text-center">برای ارتباط با ما بر روی پشتیبانی آکادمی یگانه کلیک(لمس) کنید</p>
                <div className="d-flex justify-content-center">
                    <a href="https://api.whatsapp.com/send/?phone=989925393467&text&app_absent=0"
                       target="_blank"
                       className="support-btn">
                        <div>
                            <img src={logo} alt="logo" width={50}/>
                            <span>پشتیبانی آکادمی یگانه</span>
                        </div>
                        <div>
                            <WhatsApp/>
                        </div>

                    </a>
                </div>
            </ModalBody>
        </Modal>
        const download_modal =
            <Modal size="md" centered isOpen={this.state.modal_download}
                   toggle={() => this.toggleModalDownload()}>
                <ModalHeader className="bg-primary d-flex justify-content-center">
                    دانلود اپلیکیشن مدرسه
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="d-flex justify-content-center">
                                <div className="my-auto mr-1 ml-1">
                                    Android
                                </div>
                                <img src={android} alt="android-logo" width="20px"/>
                            </div>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank"
                               className="mt-1 d-flex justify-content-center">
                                <img src={play} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank"
                               className="mt-1 d-flex justify-content-center">
                                <img src={bazaar} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank"
                               className="mt-1 d-flex justify-content-center">
                                <img src={myket} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="d-flex justify-content-center">
                                <div className="my-auto mr-1 ml-1">
                                    IOS
                                </div>
                                <img src={apple} alt="apple-logo" width="20px"/>
                            </div>
                            <a href=""
                               target="_blank" className="mt-1 d-flex justify-content-center">
                                <img src={sibiranilogobanner} className="app-download"
                                     alt="download-app"/>
                            </a>
                            <a href="" target="_blank" className="mt-1 d-flex justify-content-center">
                                <img src={IappsBadgeGray} className="app-download"
                                     alt="download-app"/>
                            </a>
                            <a href="" target="_blank" className="mt-1 d-flex justify-content-center">
                                <img src={anardoniBadgeDarkPersian} className="app-download"
                                     alt="download-app"/>
                            </a>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>;

        let is_home = this.props.location && this.props.location.pathname === "/";
        let Moderate = false;
        if (this.props.user.roles)
            Moderate = this.props.user.roles.includes(Roles.Moderate);
        return (
            <>
                <BrowserView>
                    {/* <div className={is_home ? "header-web" : "header-web full-background"}>
                        <div className="d-flex w-100 justify-content-center">
                            <div className="text-center my-auto mr-1">
                                <h1 className="title">آکادمی یگانه</h1>
                                <p className="sub-title">خودآگاهی، مدیتیشن و شفای درون</p>
                                <p className="hermes-wise">Hermes the wise school</p>
                                <div className="d-flex justify-content-around">
                                    <img src={caduceus} alt="آکادمی یگانه" width="35px" className="my-auto"/>
                                    <img src={selfcare} alt="آکادمی یگانه" width="35px" className="my-auto"/>
                                    <img src={wellness} alt="آکادمی یگانه" width="35px" className="my-auto"/>
                                </div>
                            </div>
                            <div className="d-flex">
                                <img src={logo} alt="آکادمی یگانه" width="135px" className="my-auto"/>
                            </div>
                        </div>
                        {this.props.user.token &&
                        <div className="user-login">
                            <div className="dropdownUser">
                                <div className="w-100">
                                    {this.props.user.name + " " + this.props.user.lastname}
                                </div>
                                <div className={"dropdown-content"}>
                                    <div className="d-flex justify-content-end">
                                        <img src={this.props.user.photo}
                                             className="dropdown-image"
                                             alt=""/>
                                    </div>
                                    {Moderate ?
                                        <>
                                            <NavLink to="/panel/dashboard">داشبورد</NavLink>
                                        </>
                                        :
                                        <>
                                            <NavLink to="/panel/my-courses">دوره های من</NavLink>
                                            <NavLink to="/panel/my-favorite">علاقه مندی ها</NavLink>
                                        </>
                                    }
                                    <NavLink to="/panel/profile">پروفایل</NavLink>
                                    <a onClick={this.toggleSupport}>تماس با پشتیبانی</a>
                                    <a onClick={this.toggleModalDownload}>دانلود اپلیکیشن</a>
                                    <div className="d-flex justify-content-around">
                                        <div className="box-ios" onClick={() => this.download("/apps/ios.ipa")}>
                                            <img src={ios} alt=""/>
                                        </div>
                                        <div className="box-android" onClick={() => this.download("/apps/android.apk")}>
                                            <img src={android} alt=""/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around msocial h-50 mt-1 mb-1">
                                        <a href="https://youtube.com/web_baran" target="_blank">
                                            <img src={youtube} alt=""/>
                                        </a>
                                        <a href="https://instagram.com/web_baran" target="_blank">
                                            <img src={insta} alt=""/>
                                        </a>
                                        <a href="https://facebook.com/web_baran" target="_blank">
                                            <img src={fb} alt=""/>
                                        </a>
                                        <a href="" target="_blank">
                                            <img src={spotify} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className={is_home ?
                        "menu-web d-flex position-relative justify-content-between"
                        :
                        "menu-web d-flex position-relative justify-content-between full-background"}>
                        <div className="d-flex">
                            {search}
                            <Menu/>
                        </div>
                        {this.props.user.token ?
                            <div className="btn-login" onClick={this.exit}>
                                خروج
                            </div>
                            :
                            <ContextLayout.Consumer>
                                {context => {
                                    return (
                                        <>
                                            <div className="btn-login" onClick={() => context.showLoginModal(true)}>
                                                ورود / ثبت نام
                                            </div>
                                        </>
                                    )
                                }
                                }
                            </ContextLayout.Consumer>
                        }
                    </div>*/}
                    <div className="header-web w-100">
                        {window.location.pathname === '/' ? (this.state.quicknews !== "" && (typeof this.state.quicknews !== "undefined")) &&
                            <div className="box-quick">
                                {/*<button onClick={this.closeQuickView} style={{zIndex: "999"}}>
                                    <CloseSharp/>
                                </button>*/}
                                <img src={news1} alt="news-image" width="80px"/>

                                <p className="box-quick-text">
                                    {this.state.quicknews}
                                </p>

                                <img src={news2} alt="news-image-2" width="80px"/>
                            </div> : null
                        }
                        <div className="top-header d-flex justify-content-between pr-1 pl-1">
                            <div className="d-flex align-items-center">
                                <MapPin size={30} className="m-auto"/>
                                {/*<RNavLink href="tel:+989127725099" className="pr-1 m-auto font-medium-2">
                                    09127725099
                                </RNavLink>*/}
                                <span className='address'>
                                    استان البرز- کرج- چهارراه امام خمینی (میدان کرج)- ابتدای خیابان شهید بهشتی- خیابان دکتر همایون- روبروی هتل سامان- کوچه سهند (نهر آب)- پلاک7- طبقه دوم
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <RNavLink href="https://www.instagram.com/elm.kohan/" className="pl-1">
                                    <Instagram size={30}/>
                                </RNavLink>
                                <RNavLink href="https://api.whatsapp.com/send/?phone=989101800448&text&app_absent=0"
                                          className="pl-1">
                                    <WhatsApp fontSize="large"/>
                                </RNavLink>
                                <RNavLink href="mailto:elm.kohan@yahoo.com" className="pl-1">
                                    <Mail size={30}/>
                                </RNavLink>
                                <RNavLink href="tel:02632404166" target="_blank">
                                    <Tooltip title="02632404166" arrow>
                                        <Phone size={40} className="pl-1"/>
                                    </Tooltip>
                                </RNavLink>
                                <RNavLink href="tel:09101800448" target="_blank">
                                    <Tooltip title="09101800448" arrow>
                                        <i className="fas fa-mobile-alt pl-1" style={{fontSize: "25px"}}></i>
                                        {/*<Phone size={40} className="pl-1"/>*/}
                                    </Tooltip>
                                </RNavLink>
                            </div>
                        </div>
                        <div className="header">
                            <Row>
                                <div className="d-flex" style={{width: "60%"}}>
                                    <div className="d-flex w-100  justify-content-between">
                                        <img src={logo} alt="آموزشگاه علم کهن" className="logo"/>
                                        <div className="text-center f-baraz">
                                            <h1 className="my-auto ">آموزشگاه علم کهن</h1>
                                            <span className="f-baraz"> (طب سنتی و مکمل) </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-auto pr-1 d-flex position-relative">
                                    <div className="my-auto position-relative ml-2">
                                        {search}
                                    </div>
                                    {this.props.user.token ?
                                        <>
                                            <ul className="nav navbar-nav navbar-nav-user w-100" style={{
                                                direction: 'ltr',
                                                padding: '0px',
                                                flex: 1
                                            }}>
                                                <UncontrolledDropdown tag="li"
                                                                      className="dropdown-user nav-item text-center">
                                                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                                                        <div className="user-nav d-sm-flex d-none">
                                                      <span className="user-name text-bold-600">
                                                        {this.props.user.name + " " + this.props.user.lastname}
                                                      </span>
                                                        </div>
                                                        <span data-tour="user">
                                                      <img
                                                          src={this.props.user.photo}
                                                          className="round"
                                                          height="40"
                                                          width="40"
                                                          alt="avatar"
                                                      />
                                                    </span>
                                                    </DropdownToggle>
                                                    <DropdownMenu left>
                                                        <DropdownItem
                                                            tag="a"
                                                            href="#"
                                                            onClick={this.goToPanel}>
                                                            <Icon.User size={14} className="ml-50"/>
                                                            <span className="align-middle">
                                                            پنل
                                                        </span>
                                                        </DropdownItem>
                                                        <DropdownItem divider/>
                                                        <DropdownItem
                                                            tag="a"
                                                            onClick={this.exit}>
                                                            <Icon.Power size={14} className="ml-50"/>
                                                            <span className="align-middle">
                                                            <FormattedMessage id="Logout"/>
                                                        </span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ul>
                                        </>
                                        :
                                        <ContextLayout.Consumer>
                                            {context => {
                                                return (
                                                    <>
                                                        <div className="btn-login"
                                                             onClick={() => context.showLoginModal(true)}>
                                                            <User size={20} className="user-icon"/>
                                                            ورود / ثبت نام
                                                        </div>
                                                    </>
                                                )
                                            }
                                            }
                                        </ContextLayout.Consumer>
                                    }
                                </div>
                            </Row>
                            <Row className="menu-container">
                                <Col md={10}>
                                    <Menu/>
                                </Col>
                                <Col md={2} className="shop-link-container">
                                    <div className="shop-link-wrapper">
                                        <a href="https://kohan-shop.ir" target="_blank" rel="noopener"
                                           className="shop-link gradient-border">
                                            ورود به کهن شاپ
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    {window.location.pathname === '/' ? (this.state.quicknews !== "" && (typeof this.state.quicknews !== "undefined")) &&
                        <div className="box-quick">
                            {/*<button onClick={this.closeQuickView} style={{zIndex: "999"}}>
                                    <CloseSharp/>
                                </button>*/}
                            <p className="box-quick-text">
                                {this.state.quicknews}
                            </p>
                        </div> : null
                    }
                    <div className="header w-100">
                        <div className="d-flex flex-1 align-items-center pl-1 navbar-wrapper">
                            <MobileNavbar
                                logo={logo}
                                search={searchbar}
                            />
                        </div>
                        {/*<div className="text-center f-baraz flex-column justify-content-center mx-1 align-items-center d-flex">*/}
                        {/*    <h5 className="my-auto ">آموزشگاه علم کهن</h5>*/}
                        {/*    <span className="f-baraz"> (طب سنتی و مکمل) </span>*/}
                        {/*</div>*/}
                        <div className="f-baraz flex-column justify-content-center px-1 align-items-center d-flex">
                            <h5>آموزشگاه علم کهن</h5>
                            <span className="f-baraz"> (طب سنتی و مکمل) </span>
                        </div>
                        {/* <div className="d-flex" style={{width: "60%"}}>
                            <div className="d-flex w-100  justify-content-between">
                                <img src={logo} alt="آموزشگاه علم کهن" className="logo"/>
                                <div className="text-center f-baraz">
                                    <h1 className="my-auto ">آموزشگاه علم کهن</h1>
                                    <span className="f-baraz"> (طب سنتی و مکمل) </span>
                                </div>
                            </div>
                        </div>*/}
                        <div className="d-flex flex-1 pr-1 justify-content-end my-auto">
                            <div className="search-wrapper-mobile my-auto">
                                {search}
                            </div>
                            {this.props.user.token ?
                                <ul className="nav navbar-nav navbar-nav-user w-100" style={{
                                    direction: 'ltr',
                                    padding: '0px',
                                    flex: 1
                                }}>
                                    <UncontrolledDropdown tag="li" className="dropdown-user nav-item text-center">
                                        <DropdownToggle tag="a"
                                                        className="nav-link dropdown-user-link d-flex flex-row align-items-center">
                                            <div className="user-nav d-sm-flex d-none">
                                      <span className="user-name text-bold-600 mx-1">
                                        {this.props.user.name + " " + this.props.user.lastname}
                                      </span>
                                            </div>
                                            <span data-tour="user">
                                      <img
                                          src={this.props.user.photo}
                                          className="round"
                                          height="40"
                                          width="40"
                                          alt="avatar"
                                      />
                                    </span>
                                        </DropdownToggle>
                                        <DropdownMenu left>
                                            <DropdownItem
                                                tag="a"
                                                href="#"
                                                onClick={this.goToPanel}>
                                                <Icon.User size={14} className="ml-50"/>
                                                <span className="align-middle">
                                            پنل
                                        </span>
                                            </DropdownItem>
                                            <DropdownItem divider/>
                                            <DropdownItem
                                                tag="a"
                                                onClick={this.exit}>
                                                <Icon.Power size={14} className="ml-50"/>
                                                <span className="align-middle">
                                            <FormattedMessage id="Logout"/>
                                        </span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ul>
                                :
                                <div className="user-mobile">
                                    <PersonIcon fontSize="large" style={{flex: 1}}
                                                onClick={() => this.props.modalToggler()}/>
                                </div>
                            }
                        </div>
                    </div>
                </MobileView>
                {/*<BrowserView>
                    <div className="click-box" onClick={() => window.open("https://kohan-shop.ir", "_blank")}>
                        ورود به کهن شاپ
                    </div>
                </BrowserView>*/}
                <MobileView>
                    <div onClick={() => {
                        this.setState({
                            mobileClick: !this.state.mobileClick
                        })
                    }}>
                        <div className={this.state.mobileClick ? "click-before active" : "click-before"}>
                            {!this.state.mobileClick &&
                            <ChevronsRight/>
                            }
                            {this.state.mobileClick &&
                            <>
                                <a href="https://kohan-shop.ir" target="_blank" rel="noopener">
                                    ورود به کهن شاپ
                                </a>
                                <ChevronsLeft className="ml-1"/>
                            </>
                            }
                        </div>
                    </div>
                </MobileView>
                {support_modal}
                {download_modal}
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth,
        UI: state.UI
    }
}
export default connect(mapStateToProps, {logout})(Header)
