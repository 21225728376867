import React, {Suspense, lazy} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import {IntlProviderWrapper} from "./utility/context/Internationalization"
import {Layout} from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import {store} from "./redux/storeConfig/store"
import Spinner from "./components/loader/spinner";
import "./components/Baran/rippleButton/RippleButton"
import "./assets/scss/Baran-React.scss";

//add jquery
import * as $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import {ToastContainer} from "react-toastify";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
    <Provider store={store}>
        <ToastContainer/>
        <Suspense fallback={<Spinner/>}>
            <Layout>
                <IntlProviderWrapper>
                    <LazyApp/>
                </IntlProviderWrapper>
            </Layout>
        </Suspense>
    </Provider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
