import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .mobile-left-bar {
    display: flex;
    padding: 15px 0;
    position: absolute;
    left: 10px;
    top: -5px;
  }
`

const Navbar = ({logo}) => {
    return (
        <Nav className="position-relative border-0" style={{flex: 1, padding: 0}}>
            <Burger
                logo={logo}
            />
        </Nav>
    )
}

export default Navbar